/**
  Tiempos font
*/

@font-face {
  font-family: 'Tiempos';
  font-style: normal;
  font-weight: 400;
  src: url("tiempos-text-web-regular.woff") format("woff"),
    url("tiempos-text-web-regular.woff2") format("woff2"),
    url("tiempos-text-web-regular.eot") format("eot");
}