/**
  Söhne font
*/

@font-face {
  font-family: 'Soehne';
  font-style: normal;
  font-weight: 400;
  src: url("soehne-web-buch.woff") format("woff"),
    url("soehne-web-buch.woff2") format("woff2"),
    url("soehne-web-buch.eot") format("eot");
}